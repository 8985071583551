
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterComponent',
  mounted() {
    if (this.$route.name === 'home') {
      const footer = document.querySelector('footer');
      footer?.classList.add('footer--other');
    }
  }
})
