
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'

export default defineComponent({
  setup () {
    useMeta({
      title: 'Micheal Adisa - Home',
      htmlAttrs: { lang: 'en', amp: true },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Micheal Adisa - Highly skilled with web performance, accessibility & UI Engineering. Approach engineering with usability and user experience as core building block.' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    })
  }
})
