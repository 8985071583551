
/* eslint-disable */

import { defineComponent } from 'vue';
import { useMeta } from 'vue-meta';
import HeaderComponent from '../components/Header.vue'
import Footer from '../components/Footer.vue';
import ContactSuccess from '@/components/ContactSuccess.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    HeaderComponent,
    Footer,
    ContactSuccess
},
  setup () {
    useMeta({
        title: 'Micheal Adisa',
        htmlAttrs: { lang: 'en', amp: true },
        meta: [
            { charset: 'utf-8' },
            { name: 'description', content: 'Micheal Adisa - Highly skilled with web performance, accessibility & UI Engineering. Approach engineering with usability and user experience as core building block.' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]
    })
  },
  data() {
    return {
      // windowTop: 0,
      isLoaded: false,
      observer: null,
      testimonials: [
        {
          name: 'Ugbodaga Israel',
          title: 'Lead Frontend Engineer, Vue Mastery',
          testimony: `Micheal is an excellent frontend engineer, I trust him to translate design to code perfectly, as a teammate, he is always available to provide help whenever possible, and has a keen eye for the tiniest details. I'd recommend Micheal anyday.`,
          profile: 'https://www.linkedin.com/in/israelugbodaga/'
        },
        {
          name: 'Oreoluwa Ojo',
          title: 'Head of Engineering, Enyata (Former)',
          testimony: `I worked with Micheal for over two years one thing I can say is that he is hardworking and passionate. He is someone you can trust that he will handle a project perfectly. He literally can fix everything in the right working environment. A life learner and a good team member. I strongly recommend Micheal. I could always trust him to translate design to code perfectly.`,
          profile: 'https://www.linkedin.com/in/ogbeniore/'
        }
      ],
    }
  },
  watch: {
    
  },
  mounted() {
    type home = {
      [key: string]: any; // 👈️ variable keys
    };
    this.$watch(
        () => {
            if(typeof this.$refs.home === 'object') {
              ((this.$refs.home as home).showNav)
              if ((this.$refs.home as home).showNav === true  && (this.$refs.home as home).clientWidth <= 968 ) {
                const homeContainer = document.querySelector('.body');
                (homeContainer as HTMLElement).style.position = "fixed";
                (homeContainer as HTMLElement).style.right = "0";
              } else {
                const homeContainer = document.querySelector('.body');
                (homeContainer as HTMLElement).style.position = "initial"
              }
            }
        },
      (val: any) => {
        return val
      }
    )
  },
});
